import React from 'react';
import { Router, Redirect } from '@reach/router';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';

function Index() {
  return (
    <Layout>
      <Seo title="Home" />
      <Router>
        <Redirect noThrow default from='/' to="/step/1" />
      </Router>
    </Layout>
  );
}

export default Index;
